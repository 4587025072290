<template>
  <el-main>
    <div class="pagecontent">
      <el-table
          ref="multipleTable"
          :data="nodelist"
          tooltip-effect="dark"
          style="width: 100%"
          row-key="id"
          border
          default-expand-all
          :tree-props="{children: 'children'}">
        <el-table-column label="Node Name" width="240">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column label="英文" prop="title_en"></el-table-column>
        <el-table-column label="塞尔维亚" prop="title_th"></el-table-column>
        <el-table-column :label="$t('global.action')" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink" @click="editnode(scope.row)">{{ $t('global.edit') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :title="nodeformdata.id?'modify node':'add node'" :visible.sync="isshowdialog"
               :close-on-click-modal="false">
      <el-form :model="nodeformdata" :rules="rules" ref="nodeform" :show-message="false">
        <el-form-item label="标题" label-width="120px" prop="title" required>
          <el-input v-model="nodeformdata.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="英文标题" label-width="120px" prop="title_en" required>
          <el-input v-model="nodeformdata.title_en" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="塞尔维亚标题" label-width="120px" prop="title_th" required>
          <el-input v-model="nodeformdata.title_th" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('nodeform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
// @ is an alias to /src
import {ORDER_CODE} from "@/libs/const";

export default {
  name: 'node',
  components: {},
  data() {
    return {
      nodelist: [],
      totalRows: 0,
      defaultnodeinfo: {
        id: 0,
        title: '',
        title_en: '',
        title_th: '',
        status: 1,
        remarks: '',
        apiuri: '',
        router: '',
        icon: '',
        ismenu: 0,
        pid: 0
      },
      nodeformdata: {},
      rules: {},
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    async getnodelist() {
      this.isbusy = true;
      let res = await this.httpget('/system/node/index',{ismenu:1});
      if (res.code === 0) {
        this.nodelist=[];
        res.data.forEach((node)=>{
          if(node.ismenu){
            this.nodelist.push(node);
          }
        })
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    },
    editnode(item) {
      this.nodeformdata = null;
      this.nodeformdata = this.cloneData(item);
      this.isshowdialog = true;
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/node/save', this.nodeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getnodelist();
        this.nodeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
  },
  mounted() {
    this.getnodelist();
  },
  computed: {
    userinfo: {
      get: function () {
        return this.$store.getters.getAdminInfo;
      }
    },
    currLang: {
      get: function () {
        return this.$store.getters.getCurrLang || 'cn';
      },
      set(val) {
        this.$store.commit('setCurrLang', val);
      }
    },
  },
}
</script>
